const state = () => ({
  loading: false,
});

const getters = {
  getLoading(state: any) {
    return state.loading;
  },
};

const mutations = {
  startLoading(state: { loading: boolean }) {
    state.loading = true;
  },
  stopLoading(state: { loading: boolean }) {
    state.loading = false;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
};
