import jwtInterceptor from "../../shared/jwtInterceptor";
import { Commit } from "vuex";
import { AnyObject } from "chart.js/types/basic";

const state = () => ({
  dareData: {
    id: String,
    owner: String,
    ownerName: String,
    takerName: String,
    taker: String,
    status: String,
    type: String,
    description: String,
    difficulty: Number,
    photos: String,
    reply: String,
    winner: String,
    ownerRate: String,
    takerRate: String,
    gesture: String,
  },
});

const getters = {
  getDareState(state: AnyObject) {
    return state.dareData;
  },
};

const actions = {
  async fetchDare({ commit }: { commit: Commit }, id: string) {
    const response = await jwtInterceptor.post(
      process.env.VUE_APP_API_URL + "/dare/view",
      { id }
    );
    commit("saveDare", response.data);
  },
  async clearDare({ commit }: { commit: Commit }) {
    commit("saveDare", {});
  },
  async createNewDare({ commit }: { commit: Commit }, payload: any) {
    const response = await jwtInterceptor.post(
      process.env.VUE_APP_API_URL + "/dare/new",
      payload
    );
    commit("saveDare", response.data);
  },
  async getNewDare({ commit }: { commit: Commit }, difficulty: number) {
    const response = await jwtInterceptor.post(
      process.env.VUE_APP_API_URL + "/dare/offer",
      { difficulty, type: "dom" }
    );
    commit("saveDare", response.data);
  },
  async finishDare({ commit }: { commit: Commit }, payload: any) {
    const response = await jwtInterceptor.post(
      process.env.VUE_APP_API_URL + "/dare/finish",
      payload
    );
    commit("saveDare", response.data);
  },
  async declineDare({ commit }: { commit: Commit }, payload: any) {
    const response = await jwtInterceptor.post(
      process.env.VUE_APP_API_URL + "/dare/decline",
      payload
    );
    commit("saveDare", response.data);
  },
  async updateDareStatus({ commit }: { commit: Commit }, data: any) {
    const response = await jwtInterceptor.post(
      process.env.VUE_APP_API_URL + "/dare/status",
      { id: data.id, status: data.status }
    );
    commit("saveDare", response.data);
  },
  async deleteDare({ commit }: { commit: Commit }, payload: any) {
    const response = await jwtInterceptor.post(
      process.env.VUE_APP_API_URL + "/dare/delete",
      { id: payload }
    );
    if (response) {
      commit("saveDare", { status: "deleted" });
    } else {
      commit("saveDare", { status: "failed to delete dare" });
    }
  },
};

const mutations = {
  saveDare(state: any, payload: any) {
    const newDareData = {
      id: payload.id,
      owner: payload.owner,
      ownerName: payload.ownerName,
      ownerAvatar: payload.ownerAvatar,
      ownerAge: payload.ownerAge,
      ownerGender: payload.ownerGender,
      taker: payload.taker,
      takerName: payload.takerName,
      status: payload.status,
      type: payload.type,
      description: payload.description,
      difficulty: payload.difficulty,
      photos: payload.photos,
      reply: payload.reply,
      winner: payload.winner,
      ownerRate: payload.ownerRate,
      takerRate: payload.takerRate,
      gesture: payload.gesture,
    };
    state.dareData = newDareData;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
