<template>
  <ion-page>
    <ion-content :fullscreen="true">
      <div id="container">
        <ion-grid fixed style="max-width: 400px">
          <ion-card> 
            <ion-card-header>
              <ion-card-title>⚠️ Page was not found</ion-card-title>
            </ion-card-header>
            <ion-card-content>
              Please verify you've typed the correct address.
            </ion-card-content>
          </ion-card>
          <ion-row class="ion-justify-content-center">
            <ion-button expand="full" @click="() => router.go(-1)"
              >Go back</ion-button
            >
          </ion-row>
        </ion-grid>
      </div>
    </ion-content>
  </ion-page>
</template>

 <script>
import { useRouter } from "vue-router";
import {
  IonButton,
  IonPage,
  IonCard,
  IonCardContent,
  IonGrid,
  IonContent,
  IonCardHeader,
  IonCardTitle,
} from "@ionic/vue";

export default {
  name: "ExploreContainer",
  props: {
    name: String,
  },
  components: {
    IonButton,
    IonPage,
    IonCard,
    IonCardContent,
    IonGrid,
    IonContent,
    IonCardHeader,
    IonCardTitle,
  },
  setup() {
    const router = useRouter();
    return { router };
  },
};
</script>

<style scoped>
#container {
  position: absolute;
  left: 0;
  right: 0;
  top: 30%;
}

#container strong {
  font-size: 20px;
  line-height: 26px;
}

#container p {
  font-size: 16px;
  line-height: 22px;
  color: #8c8c8c;
  margin: 0;
}

#container a {
  text-decoration: none;
}
</style>