
import { mapGetters } from "vuex";
import {
  IonTabBar,
  IonTabButton,
  IonTabs,
  IonLabel,
  IonIcon,
  IonPage,
  IonRouterOutlet,
} from "@ionic/vue";
import {
  gameController,
  home,
  play,
  person,
  search,
  personCircle,
  list,
  logOut,
  ellipsisHorizontal,
  ellipsisVertical,
  notifications,
  chatbubbles,
} from "ionicons/icons";

export default {
  components: {
    IonLabel,
    IonTabs,
    IonTabBar,
    IonTabButton,
    IonIcon,
    IonPage,
    IonRouterOutlet,
  },
  computed: {
    ...mapGetters("auth", {
      authData: "getAuthData",
    }),
  },
  setup() {
    return {
      logOut,
      play,
      gameController,
      list,
      search,
      person,
      home,
      personCircle,
      ellipsisHorizontal,
      ellipsisVertical,
      notifications,
      chatbubbles,
    };
  },
};
