
import { IonPage, IonRouterOutlet } from "@ionic/vue";
import { add, home, enter, chatbubbles, notifications } from "ionicons/icons";
import { defineComponent } from "vue";

export default defineComponent({
  name: "GuestTabs",
  props: {
    timeout: { type: Number, default: 1000 },
    name: String,
  },
  components: {
    IonPage,
    IonRouterOutlet,
  },
  setup() {
    return {
      enter,
      add,
      home,
      chatbubbles,
      notifications,
    };
  },
});
